import clsx from 'clsx';
import { GetServerSideProps } from 'next';
import { SSRConfig as nextI18NextSSRConfig, useTranslation } from 'next-i18next';
import dynamic from 'next/dynamic';
import { useRouter } from 'next/router';
import { ReactElement, Suspense, lazy, useCallback, useEffect, useRef, useState } from 'react';
import { SWRConfig, unstable_serialize as unstableSerialize } from 'swr';
import { fetchStaticCmsByType } from 'api/cms';
import { fetchContentfulPostsBySegment } from 'api/contenful';
import { fetchCategories } from 'api/seo';
import CategoriesInternalLinks from 'components/CategoriesInternalLinks/CategoriesInternalLinks';
import HtmlBlock from 'components/HtmlBlock/HtmlBlock';
import Layout from 'components/Layout/Layout';
import { SegmentTab as SegmentTabRevamp } from 'components/Layout/MobileLayout/SegmentTabs/SegmentTabsRevamp';
import PostTypeLayout from 'components/PostType/PostTypeLayout';
import { HrefLang, OpenGraphTags, PageInfo } from 'components/SEO';
import SponsoredBanner from 'components/SponsoredBanner/SponsoredBanner';
import ToastRenderer from 'components/ToastRenderer';
import { CitrusTracking } from 'components/ZAP';
import { HomeSkeletonContent } from 'components/homepage/HomeSkeletonContent';
import InteractiveJourneyWidgetContainer from 'components/interactive-journey/InteractiveJourneyWidget/InteractiveJourneyWidgetContainer';
import SegmentUniversalLinks from 'components/pdv/SegmentUniversalLinks/SegmentUniversalLinks';
import { FF_CONFIG_BY_ROUTE } from 'constants/bob-feature-flags';
import FeatureFlags from 'constants/feature-flags';
import { SegmentMenuItem } from 'constants/header-categories-configuration';
import { APP_LOGO_IMAGE_URL } from 'constants/images';
import { Routes } from 'constants/routes';
import { TrackingDataProps } from 'constants/tracking';
import { useTwilioUserTrait } from 'hooks/api/useTwilioUserTrait';
import useSegmentPageData from 'hooks/useSegmentPageData';
import { NextPageWithLayout } from 'pages/_app.page';
import { createQueryParams } from 'services/contentful/queries/segmentQuery';
import { useLandingPageDispatch, useLandingPageStore } from 'stores/landingPage';
import { selectLandingPage } from 'stores/landingPage/selectors';
import { EntryResponse } from 'types/contentful/ContentfulType';
import { FetchStatus } from 'types/post-types/PostTypes';
import {
  constructCacheKey,
  fetchBobFeatureFlags,
  isBobFeatureFlagEnabled,
} from 'utils/bob-feature-flags';
import { isSegment } from 'utils/catalog/parse-page-from-url';
import { getStaticAsset } from 'utils/domains';
import { serverSideTranslations } from 'utils/i18n';
import { isMobileScreenSize } from 'utils/screen-size';
import { setUserSegment } from 'utils/segment';
import fetchLayoutData from 'utils/server-layout-data';
import { preloadStoreState } from 'utils/stores/preloadStoreState';
import { initialiseTrackingData } from 'utils/tracking';
import { trackingHomepageSegmentViewed } from './[segment].tracking';

const SegmentAsyncSection = lazy(() => import('./SegmentAsyncSection'));
const ModalDialogRenderer = dynamic(() => import('components/ModalDialog/ModalDialogRenderer'), {
  ssr: false,
});

interface SegmentPageProps extends nextI18NextSSRConfig {
  activeSegment: string;
  seoText?: string;
  pageTitle?: string;
  metaDescription?: string;
  uspDataText?: string;
  hreflangTag?: string;
  contentfulPostsFromServer?: EntryResponse;
  fallback?: { [key: string]: (FeatureFlags.BobFlag | FeatureFlags.OptimizelyFlag)[] };
  segments: SegmentMenuItem[];
}

const SegmentPage: NextPageWithLayout<SegmentPageProps> = ({
  activeSegment,
  seoText,
  pageTitle,
  metaDescription,
  hreflangTag,
  contentfulPostsFromServer,
  fallback,
  segments,
}) => {
  const segmentData = useLandingPageStore(selectLandingPage);
  const { push, asPath } = useRouter();
  const { setContentfulPosts } = useLandingPageDispatch();
  const [isLoading, setIsLoading] = useState(true);
  const [fallbackBanner, setFallbackBanner] = useState('');

  const [skeletonPosts, setSkeletonPosts] = useState(
    contentfulPostsFromServer?.response?.posts || [],
  );

  const fetchData = useSegmentPageData({
    activeSegment,
    contentfulPostsFromServer,
    setSkeletonPosts,
  });

  const { t } = useTranslation();
  const isLoadingFirstImage = useRef(isLoading);

  useTwilioUserTrait();

  const getFallbackBanner = useCallback(() => {
    return fetchStaticCmsByType({
      key: `fallback_banners_${activeSegment}?item=banner&item=mobile_banner`,
      type: 'staticBlock',
    });
  }, [activeSegment]);

  useEffect(() => {
    setUserSegment(activeSegment);
    setIsLoading(true);
    isLoadingFirstImage.current = true;
    trackingHomepageSegmentViewed(activeSegment);
  }, [activeSegment]);

  useEffect(() => {
    if (
      segmentData?.[activeSegment]?.fetchStatus === FetchStatus.Loaded &&
      segmentData?.[activeSegment]?.posts?.length === 0
    ) {
      getFallbackBanner().then((res) => {
        if (!res) {
          return;
        }

        const { data } = res;
        const isMobile = isMobileScreenSize();
        const content = isMobile ? data?.MobileBanner : data?.Banner;

        if (content) {
          setFallbackBanner(content);

          return;
        }

        //navigate page when empty content cms and empty posts data
        if (!seoText && activeSegment !== 'women') {
          push('/s/women');
        }
      });
    }
  }, [activeSegment, fallbackBanner, getFallbackBanner, push, segmentData, seoText]);

  useEffect(() => {
    if (
      fetchData.data?.fetchStatus === FetchStatus.Loaded &&
      segmentData?.[activeSegment].fetchStatus === FetchStatus.Loading
    ) {
      setContentfulPosts(
        activeSegment,
        fetchData.data?.response || { posts: [], fetchStatus: FetchStatus.Loaded },
      );
    }
  }, [fetchData.data?.fetchStatus, activeSegment, fetchData.data, segmentData, setContentfulPosts]);

  useEffect(() => {
    const trackingData: TrackingDataProps = {
      activeSegment,
    };

    initialiseTrackingData(trackingData);
    setFallbackBanner('');
  }, [activeSegment]);

  // we will catch the fist image was render
  const renderedCallback = useCallback(() => {
    if (isLoadingFirstImage.current) {
      isLoadingFirstImage.current = false;
      setIsLoading(false);
    }
  }, []);

  const {
    fetchStatus,
    backgroundColor,
    postsSpacing,
    posts = [],
    listAbTestFlag,
  } = segmentData[activeSegment] || {};

  return (
    <SWRConfig value={{ fallback }}>
      <div className="pt-4">
        {/* Head */}
        <PageInfo
          title={pageTitle || t('ZALORA Online Fashion Shopping')}
          canonical={`/s/${activeSegment}`}
          description={metaDescription}
        />

        <Suspense fallback={null}>
          <SegmentAsyncSection />
        </Suspense>

        <OpenGraphTags
          title={pageTitle || ''}
          description={metaDescription}
          image={getStaticAsset(APP_LOGO_IMAGE_URL, false)}
          imageWidth="600"
          imageHeight="600"
          url={asPath}
        />

        <HrefLang hrefLangTag={hreflangTag} />
        <SegmentUniversalLinks segment={activeSegment} />
        <CitrusTracking />

        <SegmentTabRevamp
          activeSegment={activeSegment}
          segments={segments}
        />

        <InteractiveJourneyWidgetContainer />

        <div className="mt-2">
          {isLoading ? (
            <div className={clsx('absolute right-0 z-10 mt-0 w-full bg-white')}>
              {<HomeSkeletonContent posts={skeletonPosts} />}
            </div>
          ) : null}

          <div className={clsx({ invisible: isLoading })}>
            {fetchStatus === FetchStatus.Loaded ? (
              <PostTypeLayout
                listAbTestFlag={listAbTestFlag}
                activeKey={activeSegment}
                fetchStatus={fetchStatus}
                backgroundColor={backgroundColor}
                postsSpacing={postsSpacing}
                posts={posts || []}
                renderedCallback={renderedCallback}
              />
            ) : null}
          </div>
        </div>

        {fallbackBanner ? (
          <div
            className="fallback-cms"
            dangerouslySetInnerHTML={{
              __html: fallbackBanner || '',
            }}
          />
        ) : null}
        <SponsoredBanner activeSegment={activeSegment} />

        {/* Page Info */}
        <div className={'mtl box mbm block'}>
          <HtmlBlock
            className="segment-info"
            id="segment-info"
            content={seoText || ''}
            stylesFile={'/static-assets/legacy/css/segment-info.css'}
          />

          <CategoriesInternalLinks
            type="row"
            className="mx-auto mt-3 max-w-screen-xl px-4"
          />
        </div>

        <ToastRenderer />
        <ModalDialogRenderer />
      </div>
    </SWRConfig>
  );
};

export const getServerSideProps: GetServerSideProps = async (context) => {
  const { locale = 'en', query } = context;
  const { segment } = query;
  const activeSegment = typeof segment === 'string' ? segment : '';
  const queryParams = createQueryParams(query, locale);

  const [
    layoutData = null,
    dataCms = null,
    translationData = null,
    contentfulPostsFromServer,
    enabledFeatureFlags,
    categories,
  ] = await Promise.all([
    fetchLayoutData(locale as string),
    fetchStaticCmsByType({
      key: `homepage_${activeSegment}`,
      items: ['seo_text', 'page_title', 'meta_description', 'hreflang_tag'],
      type: 'staticPage',
      locale,
    }),
    serverSideTranslations(locale, ['common', 'footer', 'interactive-journey', 'segment']),
    fetchContentfulPostsBySegment(activeSegment, queryParams),
    fetchBobFeatureFlags({
      routeKey: Routes.SEGMENT,
      featureFlagConfig: FF_CONFIG_BY_ROUTE,
      options: { isServerOnly: true },
    }),
    fetchCategories(activeSegment, locale).catch(() => []), // avoid throwing error, this error can be ignored
  ]);

  if (layoutData?.segments && !isSegment(activeSegment, layoutData.segments)) {
    return { notFound: true };
  }

  const enablePersonalizeSegmentFlag = isBobFeatureFlagEnabled(
    enabledFeatureFlags,
    FeatureFlags.Bob.LOTUS_ENABLE_CONTENTFUL_PERSONALISATION,
  );

  return {
    props: {
      ...translationData,
      ...preloadStoreState({
        ui: {
          activeSegment,
          ...layoutData,
        },
        optimizely: {},
        landingPage: {
          enablePersonalizeSegmentFlag,
          contentfulData: {
            [activeSegment]: {
              fetchStatus: FetchStatus.Loading,
            },
          },
        },
        cart: {},
      }),
      contentfulPostsFromServer,
      fallback: {
        [unstableSerialize(constructCacheKey(Routes.SEGMENT))]: enabledFeatureFlags, // Pass FF in fallback for server side use cases
        [unstableSerialize(['/v1/seo/categories', activeSegment])]: categories,
      },
      activeSegment,
      seoText: dataCms?.data?.SeoText || '',
      pageTitle: dataCms?.data?.PageTitle || '',
      metaDescription: dataCms?.data?.MetaDescription || '',
      hreflangTag: dataCms?.data?.HreflangTag || '',
      segments: layoutData?.segments,
    },
  };
};

SegmentPage.getLayout = (page: ReactElement) => {
  return <Layout shouldShownBackToTop>{page}</Layout>;
};

export default SegmentPage;
