import { Grid as BaseGrid } from '@zalora/post-types';
import { Platform } from '@zalora/post-types/lib/Grid/types';
import { BleedType, LoadingState, MediaType } from '@zalora/post-types/lib/common/types';
import clsx from 'clsx';
import { FC, memo } from 'react';
import useSWRImmutable from 'swr/immutable';
import { getSponsoredBanner } from 'api/sponsored';
import MediaLink from 'components/PostType/MediaLink';
import { CONTENT_TYPE } from 'constants/contentful';
import { PRE_WRAPPER_CLASS } from 'constants/post-types';
import useUser from 'hooks/api/useUser';
import { getCountryCode } from 'utils/countries';
import { displayImageNumber, getDateTimePostType, isMediaAvailable } from 'utils/post-type/helper';
import { trackingClick, trackingImpression } from 'utils/tracking/zap';

interface Props {
  activeSegment: string;
}

interface SponsoredBanner {
  id?: string;
  originImageUrl?: string;
  linkUrl?: string;
  text?: string;
}

const SponsoredBanner: FC<Props> = ({ activeSegment }) => {
  const countryCode = getCountryCode()?.toUpperCase() || '';
  const { data: user } = useUser();

  const { data } = useSWRImmutable(
    `sponsored-banner-${activeSegment}-${countryCode}-${user?.Zuid || ''}`,
    () =>
      getSponsoredBanner({ segment: activeSegment, countryCode, zuid: user?.Zuid }).then(
        (response) => {
          const images = response?.banners || [];
          const displayImage = displayImageNumber(images);

          return images
            ?.slice(0, displayImage)
            .map(({ id, originImageUrl, linkUrl, text }: SponsoredBanner, index: number) => {
              return {
                entryId: id,
                contentType: CONTENT_TYPE.INTERNAL_MEDIA,
                mediaUrl: originImageUrl,
                navLink: linkUrl,
                title: text,
                type: MediaType.Image,
                placement: `sponsored-banner-${id}`,
                position: index,
              };
            });
        },
      ),
  );

  return (
    <div className={PRE_WRAPPER_CLASS.HALF_BLEED_PRE_WRAPPER_CLASS}>
      <BaseGrid
        className={clsx({ hidden: !isMediaAvailable(data) })}
        internalName={''}
        title={'Sponsored Brands'}
        description={''}
        media={data || []}
        rows={data?.length / 3}
        columns={3}
        getTime={() => getDateTimePostType()}
        MediaLink={(props) => (
          <MediaLink
            onClick={() => {
              trackingClick(props.data.entryId);
            }}
            {...props}
          />
        )}
        loadingState={LoadingState.Loaded}
        platform={Platform.Desktop}
        bleedType={BleedType.Half}
        onImpression={(image) => {
          trackingImpression(image.entryId);
        }}
      />
    </div>
  );
};

export default memo(SponsoredBanner);
