import clsx from 'clsx';
import Link from 'next/link';
import { FC, useEffect } from 'react';
import { handleScrollToActiveElement, useScrollToEndElement } from 'hooks/useScrollToElement';
import { Segment } from 'types/api/catalog/Segments';
import { SegmentTabsArrow } from './SegmentTabsArrow';

const ID_SEGMENT_TAB = 'segmentTab';
const ACTIVE_SEGMENT_CLASS_NAME = 'js-active-segment';

interface Props {
  activeSegment?: string;
  segments?: Segment[];
}

export const SegmentTab: FC<Props> = ({ segments, activeSegment }) => {
  const { enableIconLeft, isScrollable, handleScrollToEnd } = useScrollToEndElement(ID_SEGMENT_TAB);

  useEffect(() => {
    handleScrollToActiveElement(ID_SEGMENT_TAB, ACTIVE_SEGMENT_CLASS_NAME);
  }, [activeSegment]);

  return (
    <div className="sticky top-16 z-10 desktop:hidden">
      <div className="relative bg-white py-3">
        <SegmentTabsArrow
          type="left"
          isHidden={!isScrollable || !enableIconLeft}
          onClick={() => handleScrollToEnd(false)}
        />

        <div
          id={ID_SEGMENT_TAB}
          className="flex flex-row overflow-x-scroll scrollbar-hide tablet:justify-center"
        >
          {segments?.map((segment) => {
            const isActive = activeSegment === segment?.id;

            return (
              <Link
                key={segment.id}
                href={`/s/${segment.id}`}
                prefetch={false}
                data-test-id={`segment-tab-${segment.id}`}
                className={clsx(
                  {
                    [ACTIVE_SEGMENT_CLASS_NAME]: isActive,
                    'bg-grey-100 text-white': isActive,
                    'bg-grey-10 text-grey-100': !isActive,
                  },
                  'mr-2 whitespace-nowrap rounded-lg px-4 py-2.5 no-underline first:ml-4 tablet:py-2 desktop:hidden',
                )}
              >
                {segment.name}
              </Link>
            );
          })}
        </div>

        <SegmentTabsArrow
          type="right"
          isHidden={!isScrollable || enableIconLeft}
          onClick={() => handleScrollToEnd(true)}
        />
      </div>
    </div>
  );
};
