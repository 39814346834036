import React, { FC, memo, useEffect } from 'react';
import { getStaticAsset } from 'utils/domains';

/**
 * Workaround solution for supporting old CMS
 */
interface Props {
  content: string;
  className?: string;
  stylesFile?: string;
  id: string;
}

const HtmlBlock: FC<Props> = ({ content, className, stylesFile, id }) => {
  const styles = stylesFile ? getStaticAsset(stylesFile) : '';

  useEffect(() => {
    if (!content || !styles) {
      return;
    }

    const element = document.createElement('link');

    element.setAttribute('rel', 'stylesheet');
    element.setAttribute('type', 'text/css');
    element.setAttribute('href', styles);
    document.head.appendChild(element);
  }, [content, styles]);

  if (!content) {
    return null;
  }

  return (
    <div
      id={id}
      className={className}
    >
      <div className={'mtl box fct-text fsm mbm block'}>
        <div dangerouslySetInnerHTML={{ __html: content }} />
      </div>
    </div>
  );
};

export default memo(HtmlBlock);
