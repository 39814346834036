import { ZDTCustomer } from '@zalora/doraemon-ts';

const SPONSORED_BANNER_URL = 'https://mbg.gfgapis.com/api/v1';
const MAX_ADS_NUMBER = 40;
const COUNTRY_CODE = {
  SG: '1',
  MY: '2',
  HK: '3',
  TW: '4',
  ID: '5',
  PH: '6',
};

export const getSponsoredBanner = async ({
  segment,
  countryCode,
  zuid,
}: {
  countryCode?: string;
  segment: string;
  zuid?: ZDTCustomer.Customer['Zuid'];
}) => {
  const params = {
    catalogId: COUNTRY_CODE[countryCode as keyof typeof COUNTRY_CODE],
    segment: segment || '',
    bannerSlotIds: ['DESKTOP_FEATURE_BRAND'],
    customerId: zuid,
    maxNumberOfAds: MAX_ADS_NUMBER,
  };

  try {
    const response = await fetch(SPONSORED_BANNER_URL, {
      method: 'POST',
      headers: {
        'Content-Type': 'application/json',
      },
      body: JSON.stringify(params),
    });

    if (response.ok) {
      const jsonData = await response.json();

      return jsonData;
    }

    return undefined;
  } catch {}
};
