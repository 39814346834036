import { ArrowLeftIcon, ArrowRightIcon } from '@zalora/zui-icons';
import clsx from 'clsx';
import { FC } from 'react';

interface Props {
  type: 'left' | 'right';
  isHidden?: boolean;
  onClick?: () => void;
}

export const SegmentTabsArrow: FC<Props> = ({ type, isHidden, onClick }) => {
  const isRightArrow = type === 'right';

  if (isHidden) {
    return null;
  }

  return (
    <button
      className={clsx(
        // Position
        'absolute top-0 h-full w-11',

        // Background
        'from-[#C1C1C1]',
        isRightArrow ? 'right-0 bg-gradient-to-l' : 'left-0 bg-gradient-to-r',

        // Visibility
        'tablet:hidden desktop:hidden',
      )}
      onClick={onClick}
    >
      {isRightArrow ? (
        <ArrowRightIcon
          width={24}
          height={24}
        />
      ) : (
        <ArrowLeftIcon
          width={24}
          height={24}
        />
      )}
    </button>
  );
};
