import { CookieKey } from 'constants/cookies';
import Cookie from './cookie';

const DEFAULT_SELECTED_SEGMENT = 'women';

export const setUserSegment = (segment: string) => {
  if (!segment) {
    return;
  }

  const expireDate = new Date();
  const cookie = new Cookie();

  expireDate.setMonth(expireDate.getMonth() + 3);

  cookie.set(CookieKey.USER_SEGMENT, segment, expireDate.toUTCString());
};

export const getLastActiveUserSegment = () => {
  const cookie = new Cookie();

  return cookie.get(CookieKey.USER_SEGMENT);
};

export const getUserSegment = () => {
  const userSegment = getLastActiveUserSegment();

  return userSegment || DEFAULT_SELECTED_SEGMENT;
};
