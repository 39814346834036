import { useRouter } from 'next/router';
import { Dispatch, SetStateAction } from 'react';
import useSWRImmutable from 'swr/immutable';
import { fetchContentfulPostsBySegment } from 'api/contenful';
import { isTrueValue } from 'services/contentful/helper';
import { createQueryParams } from 'services/contentful/queries/segmentQuery';
import { useLandingPageDispatch } from 'stores/landingPage';
import { EntryResponse } from 'types/contentful/ContentfulType';
import { FetchStatus, RawPostType } from 'types/post-types/PostTypes';
import { isMobileScreenSize } from 'utils/screen-size';

const useSegmentPageData = ({
  activeSegment,
  contentfulPostsFromServer,
  setSkeletonPosts,
}: {
  activeSegment: string;
  contentfulPostsFromServer: EntryResponse | undefined;
  setSkeletonPosts: Dispatch<SetStateAction<RawPostType[]>>;
}) => {
  const { setContentfulPosts } = useLandingPageDispatch();
  const { locale = 'en', query } = useRouter();

  /**
   * To enhance performance, we've relocated the Contentful data fetching
   * process to the server. However, one of our principles is to maintain cache
   * ability, which means we cannot fetch content based on device type.
   * Currently, our primary focus is on mobile devices, so by default, we'll
   * fetch Contentful data for mobile devices (mWeb) on the server-side.
   *
   * The data will be fetched again on the client-side, if:
   *   - The page is in preview mode (desktop/mobile), or
   *   - The device is not a mobile.
   *
   * This is the trade-off to optimize performance on mobile devices.
   */
  return useSWRImmutable(
    `segment-${activeSegment}`,
    () => {
      /**
       * Note: Using useDeviceType hook to determine the device type
       * does not work properly in this case. Therefore, isMobileScreenSize
       * is used to determine if the device is a mobile or not.
       */
      if (isTrueValue(query?.isPreviewMode) || !isMobileScreenSize()) {
        const queryParams = createQueryParams(query, locale, false);

        return fetchContentfulPostsBySegment(activeSegment, queryParams);
      }

      return contentfulPostsFromServer;
    },
    {
      onSuccess: async (data) => {
        const { response } = data || {};
        const posts = response?.posts || [];

        setContentfulPosts(
          activeSegment,
          response ? response : { posts: [], fetchStatus: FetchStatus.Loaded },
        );

        setSkeletonPosts(posts);
      },
    },
  );
};

export default useSegmentPageData;
