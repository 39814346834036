import { FC } from 'react';
import { UniversalLinks } from 'components/SEO';

interface Props {
  segment: string;
}

const SegmentUniversalLinks: FC<Props> = ({ segment }) => {
  return <UniversalLinks destination={`seg/${segment}`} />;
};

export default SegmentUniversalLinks;
